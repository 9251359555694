export default {
	application: { 
		title: 'sans-demo',
		description: 'Demo application'
	},
	cdn: {
//		URL: "https://d39l53tro9n1iy.cloudfront.net"
		URL: "demo.sans-caffeine.com"
	},
  api: {
//		URL: "https://u09q507lj4.execute-api.eu-west-2.amazonaws.com/demo"
		URL: "https://api.demo.sans-caffeine.com/v1"
  },
  authentication: {
    AUTH_CLIENT: "220hj8gt5ee77vus8dra13secu"
  }
}